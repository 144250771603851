import { Radio, RadioGroup } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { FormGroup } from "@components/FormGroup";
import { InputGroup } from "@components/InputGroup";
import {
  RequirementAutoNumbering,
  RequirementAutoNumberingFormat,
  RequirementNumberingType,
} from "@rollup-api/models/workspace-settings/requirementSettings.model";
import { DOC_ID_PLACEHOLDER, REQ_NUMBER_PLACEHOLDER } from "@store/Requirements/RequirementsSettingsStore";

import styles from "./RequirementsConfiguration.module.scss";

const RequirementsConfiguration = () => {
  const workspace = useWorkspace();
  const { settings } = workspace.requirementsModule;
  const { autoNumbering, isAutoNumberingDisabled, autoNumberingFormat, setAutoNumbering, setAutoNumberingFormat } = settings;
  const { numberingType, numberingSchema, setNumberingSchema, setNumberingType } = settings;

  return (
    <div className={styles.requirementsConfiguration}>
      <div className={styles.requirementsConfigurationInnerContainer}>
        <Text variant={TextVariant.H1} disableAlignmentWrapper>
          Configuration
        </Text>
        <div className={styles.body}>
          <div className={styles.section}>
            <Text variant={TextVariant.H3}>Auto-Numbering</Text>
            <RadioGroup selectedValue={autoNumbering} onChange={e => setAutoNumbering(e.currentTarget.value as RequirementAutoNumbering)}>
              <Radio className={styles.requirementsConfigurationRadio} value={RequirementAutoNumbering.Enabled} label="Enabled" />
              <Radio className={styles.requirementsConfigurationRadio} value={RequirementAutoNumbering.Disabled} label="Disabled" />
            </RadioGroup>
          </div>
          <div className={styles.section}>
            <Text variant={TextVariant.H3}>Numbering Type</Text>
            <RadioGroup
              selectedValue={numberingType}
              onChange={e => setNumberingType(e.currentTarget.value as RequirementNumberingType)}
              disabled={isAutoNumberingDisabled}
            >
              <Radio className={styles.requirementsConfigurationRadio} value={RequirementNumberingType.Static} label="Static" />
              <Radio className={styles.requirementsConfigurationRadio} value={RequirementNumberingType.Dynamic} label="Dynamic" />
            </RadioGroup>
          </div>
          <div className={styles.section}>
            <Text variant={TextVariant.H3}>Auto-Numbering Format</Text>
            <RadioGroup
              selectedValue={autoNumberingFormat}
              onChange={e => setAutoNumberingFormat(e.currentTarget.value as RequirementAutoNumberingFormat)}
              disabled={isAutoNumberingDisabled}
            >
              <Radio value={RequirementAutoNumberingFormat.IntegerIncrement}>
                <div className={styles.requirementsConfigurationRadioContainer}>
                  <Text variant={TextVariant.Body}>Integer Increment</Text>
                  <Text variant={TextVariant.BodyDimmed}>Each new (static) or subsequent (dynamic) requirement will increment by 1</Text>
                  <Text className={styles.example} variant={TextVariant.BodyDimmed} disableAlignmentWrapper>
                    1, 2, 3, 4...
                  </Text>
                </div>
              </Radio>
              <Radio value={RequirementAutoNumberingFormat.SectionBased}>
                <div className={styles.requirementsConfigurationRadioContainer}>
                  <Text variant={TextVariant.Body}>Section-based</Text>
                  <Text variant={TextVariant.BodyDimmed}>Nested headings and section numbers will be applied</Text>
                  <Text className={styles.example} variant={TextVariant.BodyDimmed} disableAlignmentWrapper>
                    1., 1.1, 1.2, 2., 2.1, 2.2...
                  </Text>
                </div>
              </Radio>
            </RadioGroup>
          </div>
          <div className={styles.section}>
            <Text variant={TextVariant.H3}>Numbering Schema</Text>
            <FormGroup label={`Fields you can use: ${DOC_ID_PLACEHOLDER}, ${REQ_NUMBER_PLACEHOLDER}}`}>
              <InputGroup
                value={numberingSchema}
                onChange={e => setNumberingSchema(e.currentTarget.value)}
                placeholder={`${DOC_ID_PLACEHOLDER}-${REQ_NUMBER_PLACEHOLDER}`}
                e2eIdentifiers="numbering-schema-input"
                disabled={isAutoNumberingDisabled}
              />
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(RequirementsConfiguration);
