import { RefObject } from "react";
import { Menu, MenuDivider, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { DocumentNameInput } from "@components/DocumentNameInput";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { MenuItemFavorite } from "@components/MenuItems/MenuItemFavorite";
import { IPopoverRefType } from "@components/Popover";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { EntityType } from "@store/types";

import { MultiplicityNavItem } from "../MultiplicityNavItem";
import { CopyLinkMenuItem, CopyPasteBlockMenuItems } from "../SmallComponents";

import styles from "./BlockMenu.module.scss";

type Props = {
  block: IBlock;
  onAddNewOpen: (block: IBlock) => void;
  popoverRef?: RefObject<IPopoverRefType>;
  onNavigateToBlock(id: string): void;
  onEnter?(): void;
};

const BlockMenu = (props: Props) => {
  const { block, popoverRef, onNavigateToBlock, onEnter } = props;

  const handleOpenAddNew = () => props.onAddNewOpen(block);

  const handleDeleteBlock = () => {
    if (!appStore.workspaceModel) {
      return;
    }

    if (block !== appStore.workspaceModel.rootBlock) {
      onNavigateToBlock(block.parentBlock?.id ?? appStore.workspaceModel.rootBlock?.id);
      appStore.workspaceModel.deleteBlock(block);
    }
  };

  const handleDetachBlock = () => {
    appStore.workspaceModel?.detachBlock(block);
  };

  const handleEnter = () => {
    popoverRef?.current?.setState({ isOpen: false });
    onEnter?.();
  };

  const isRootBlock = block === appStore.workspaceModel?.rootBlock;
  const hasChildren = !!block.children?.length;

  const detachFromTreeTooltipMsg = (() => {
    if (isRootBlock) {
      return "Cannot detach root block";
    } else if (hasChildren) {
      return "Cannot detach block with children";
    }
  })();

  const deleteTooltipMsg = (() => {
    if (isRootBlock) {
      return "Cannot delete root block";
    }
  })();

  return (
    <Menu>
      <p className={styles.blockMenuNameLabel}>Name</p>
      <DocumentNameInput className={styles.blockMenuNameInput} onUpdate={block.setLabel} onEnter={handleEnter} defaultValue={block.label} />
      <MenuItem icon="new-layers" text="Add child block" onClick={handleOpenAddNew} e2eIdentifiers="new-layers" />
      <CopyPasteBlockMenuItems block={block} />
      {!isRootBlock && <MultiplicityNavItem block={block} />}
      <CopyLinkMenuItem node={block} />
      <MenuDivider title="Actions" />
      <MenuItemFavorite entityId={block.id} entityType={EntityType.Block} />
      <Tooltip className={styles.blockMenuPopover} content={detachFromTreeTooltipMsg} disabled={!detachFromTreeTooltipMsg}>
        <MenuItem
          icon="th-list"
          text="Detach from tree"
          disabled={isRootBlock || hasChildren}
          onClick={handleDetachBlock}
          e2eIdentifiers="detach-from-tree"
        />
      </Tooltip>
      <Tooltip className={styles.blockMenuPopover} content={deleteTooltipMsg} disabled={!deleteTooltipMsg}>
        <MenuItemDelete onDelete={handleDeleteBlock} disabled={isRootBlock} />
      </Tooltip>
    </Menu>
  );
};

export default observer(BlockMenu);
