import { useCallback, useEffect, useState } from "react";
import { Icon, Intent } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import { Button } from "@components/Button";
import CollapsibleCard from "@components/CatalogItemPreview/Components/CollapsibleCard";
import PdmVersionEditor from "@components/PdmVersionEditor";
import { Tooltip } from "@components/Tooltip";
import appStore from "@store/AppStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { PdmCard } from "@store/PdmCardsVisibilityStore";

import { tooltipContent } from "./constants";

import styles from "./SpecificationsCard.module.scss";

type Props = {
  catalogItem: ICatalogItem;
  activeVersion: string;
  isVertical?: boolean;
  disabled?: boolean;
  onVersionChange: (id: string) => void;
};

const SpecificationsCard = (props: Props) => {
  const { catalogItem, activeVersion, onVersionChange, isVertical, disabled } = props;
  const version = appStore.orgModel.catalogItems.getCatalogItemVersion(activeVersion);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [addRevision, setAddRevision] = useState(false);
  const [material, setMaterial] = useState("");
  const [cost, setCost] = useState("0");
  const [costCurrency, setCostCurrency] = useState("$");
  const [weight, setWeight] = useState("0");
  const [weightUnit, setWeightUnit] = useState("kg");
  const [comment, setComment] = useState("");
  const [revisionCode, setRevisionCode] = useState<string>();
  const [revisionName, setRevisionName] = useState("");
  const [file, setFile] = useState<File | null>(null);

  const setDefaults = useCallback(() => {
    if (version) {
      setMaterial(version.material || "");
      setCost(version.cost ? version.cost.toString() : "0");
      setCostCurrency(version.costCurrency || "$");
      setWeight(version.weight ? version.weight.toString() : "0");
      setWeightUnit(version.weightUnit || "kg");
      setComment(version.comment || "");
      setRevisionCode(version.revisionCode || undefined);
      setRevisionName(version.revisionName || "");
    }
  }, [version]);

  useEffect(() => {
    setDefaults();
  }, [setDefaults, version, version?.updatedAt]);

  const toggleEditMode = () => setEditMode(!editMode);

  const handleCancel = () => {
    setDefaults();
    toggleEditMode();
  };

  const handleSave = async (updateCurrent = false, isDraft = false) => {
    if (!version) {
      return;
    }

    setLoading(true);

    let attachmentId: string | undefined;

    if (file) {
      attachmentId = await appStore.orgModel.attachments.add({ label: file.name }, file);
    }

    const versionData = {
      material,
      cost: Number(cost),
      costCurrency,
      weight: Number(weight),
      weightUnit,
      revisionCode,
      comment,
      isDraft,
      attachmentId,
      revisionName: addRevision ? revisionName : undefined,
    };

    if (version.isDraft || updateCurrent) {
      await appStore.orgModel.catalogItems.updateCatalogItemVersion(version.id, versionData);
    } else {
      const id = uuidv4();
      await appStore.orgModel.catalogItems.createCatalogItemVersion({
        id,
        catalogItemId: catalogItem.id,
        ...versionData,
      });
      onVersionChange(id);
    }

    setLoading(false);
    toggleEditMode();
  };

  const renderContent = () => {
    if (editMode) {
      return (
        <PdmVersionEditor
          isSaving={loading}
          catalogItem={catalogItem}
          version={version}
          file={file}
          setFile={setFile}
          addRevision={addRevision}
          setAddRevision={setAddRevision}
          costCurrency={costCurrency}
          setCostCurrency={setCostCurrency}
          cost={cost}
          setCost={setCost}
          weight={weight}
          setWeight={setWeight}
          weightUnit={weightUnit}
          setWeightUnit={setWeightUnit}
          material={material}
          setMaterial={setMaterial}
          comment={comment}
          setComment={setComment}
          revisionName={revisionName}
          setRevisionName={setRevisionName}
          setRevisionCode={setRevisionCode}
        />
      );
    }

    return (
      <div className={styles.specificationsCardContent}>
        <div className={styles.specificationsCardRow}>
          <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
            Primary Material:
          </Text>
          <Text variant={TextVariant.Body}>{material}</Text>
        </div>
        <div className={styles.specificationsCardRow}>
          <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
            Cost:
          </Text>
          <Text variant={TextVariant.Body}>
            {costCurrency} {cost}
          </Text>
        </div>
        <div className={styles.specificationsCardRow}>
          <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
            Weight:
          </Text>
          <Text variant={TextVariant.Body}>
            {weight} {weightUnit}
          </Text>
        </div>
        <div className={classNames(styles.specificationsCardRow, styles.specificationsCardComment)}>
          <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
            Comment:
          </Text>
          <Text variant={TextVariant.Body}>{comment}</Text>
        </div>
        {version?.revisionCode && (
          <div className={styles.specificationsCardRow}>
            <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
              Revision Code:
            </Text>
            <Text variant={TextVariant.Body}>{version.revisionCode}</Text>
            <Tooltip
              className={styles.specificationsCardTooltip}
              content="This is incremented on every new version. It's cannot be changed"
            >
              <Icon icon="info-sign" size={12} />
            </Tooltip>
          </div>
        )}
        {version?.revisionName && (
          <div className={styles.specificationsCardRow}>
            <Text className={styles.specificationsCardLabel} variant={TextVariant.Caption}>
              Revision Name:
            </Text>
            <Text variant={TextVariant.Body}>{version.revisionName}</Text>
          </div>
        )}
      </div>
    );
  };

  const renderRightActions = () => {
    if (disabled) {
      return;
    }

    if (editMode) {
      return (
        <>
          <Button disabled={loading} onClick={handleCancel} minimal e2eIdentifiers="cancel-specification">
            Cancel
          </Button>
          <Button
            tooltip="Save these updates as a draft version, return to this later"
            disabled={loading}
            onClick={() => handleSave(false, true)}
            outlined
            e2eIdentifiers="save-draft-specification"
          >
            Save as draft
          </Button>
          <Button
            tooltip="Save these updates as a new version"
            disabled={loading}
            onClick={() => handleSave()}
            intent={Intent.PRIMARY}
            e2eIdentifiers="save-specification"
          >
            Save as new
          </Button>
          <Button
            tooltip="Update current version"
            disabled={loading}
            onClick={() => handleSave(true)}
            intent={Intent.PRIMARY}
            e2eIdentifiers="save-specification"
          >
            Update
          </Button>
        </>
      );
    }

    return (
      <Button onClick={toggleEditMode} className={styles.specificationsCardEdit} minimal e2eIdentifiers="edit-specification" icon="edit">
        Edit
      </Button>
    );
  };

  return (
    <CollapsibleCard
      visible={isVertical ? true : appStore.env.pdmCardVisibility.specifications}
      onToggle={() => appStore.env.pdmCardVisibility.toggleCard(PdmCard.Specifications)}
      className={styles.specificationsCard}
      width={editMode && !isVertical ? 550 : "auto"}
      version={!editMode ? version?.index : undefined}
      title="Specifications"
      tooltip={tooltipContent}
      disabled={isVertical}
      rightActions={renderRightActions()}
    >
      {renderContent()}
    </CollapsibleCard>
  );
};

export default observer(SpecificationsCard);
