import { useState } from "react";
import omitBy from "lodash/omitBy";

import { CatalogItemCsvColumn } from "@components/CatalogItems/Table/types";
import { CsvImport } from "@components/CsvImport";
import { CatalogItemCsvColumnMap } from "@rollup-api/models/catalogItem/catalogItemDtos";
import appStore from "@store/AppStore";

import { catalogItemColumnNameMap, defaultColumnsMap } from "./constants";

const CatalogItemCsvImport = () => {
  const [columnMap, setColumnMap] = useState<CatalogItemCsvColumnMap>(defaultColumnsMap);

  const handleUpload = (file: File) => {
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    appStore.orgModel.uploads.addNewFileUpload({
      files: dataTransfer.files,
      onUpload: attachmentId =>
        appStore.orgModel.catalogItems.createCsvCatalogItem(
          attachmentId,
          file,
          JSON.stringify(omitBy(columnMap, v => !v)),
          appStore.workspaceModel?.id
        ),
    });
  };

  return (
    <CsvImport<CatalogItemCsvColumn>
      columnMap={columnMap}
      columnNameMap={catalogItemColumnNameMap}
      onClose={appStore.ui.hideCatalogItemCreationModal}
      onUpload={handleUpload}
      onChangeColumnMap={setColumnMap}
    />
  );
};

export default CatalogItemCsvImport;
