import { GridOptions, IGroupCellRendererParams, InitialGroupOrderComparatorParams, IRowDragItem } from "ag-grid-community";

import GroupRowInnerRenderer from "@components/BomTables/Table/Cells/GroupRowInnerRenderer";
import { getRowId } from "@components/BomTables/Table/utils";
import { BomTableNode } from "@store/BomTable/BomTableStore";

export const defaultColDef = {
  editable: false,
  autoHeight: false,
  singleClickEdit: true,
};

const groupRowRendererParams: IGroupCellRendererParams = {
  suppressCount: true,
  innerRenderer: GroupRowInnerRenderer,
};

const initialGroupOrderComparator = (params: InitialGroupOrderComparatorParams<BomTableNode>) => {
  const a = params.nodeA.key || "";
  const b = params.nodeB.key || "";
  return a < b ? -1 : a > b ? 1 : 0;
};

const getRowDragText = (params: IRowDragItem) => {
  const bomRow = params.rowNode?.data as BomTableNode | undefined;
  return bomRow?.catalogItem?.name || "BOM Table Row";
};

export const bomTableGridOptions: GridOptions<BomTableNode> = {
  groupRowRendererParams,
  initialGroupOrderComparator,
  rowDragText: getRowDragText,
  groupDisplayType: "groupRows",
  groupDefaultExpanded: 1,
  getRowId,
  rowSelection: "multiple",
  defaultColDef,
  alwaysShowVerticalScroll: true,
  rowDragManaged: true,
};
