import { useState } from "react";
import { Menu, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import { TaskStatus, taskStatusIcons } from "@components/ProjectManagementSystem/Types/TaskStatus";
import { Text, TextVariant } from "src/ui/Text";

import "./StatusSelector.scss";

export interface TaskStatusSelectorProps {
  currentStatus?: TaskStatus;
  onChange?: (status: TaskStatus) => {};
}

const TaskStatusSelector = ({ currentStatus: currentStatusProp, onChange }: TaskStatusSelectorProps) => {
  const [currentStatus, setCurrentStatus] = useState<TaskStatus>(currentStatusProp || TaskStatus.Backlog);

  if (!currentStatus) {
    return null;
  }

  const handleClick = (status: TaskStatus) => {
    setCurrentStatus(status);
    onChange?.(status);
  };

  const renderMenu = () => {
    return (
      <Menu>
        {Object.entries(taskStatusIcons).map(([status, icon]) => (
          <MenuItem
            key={status}
            icon={icon}
            text={status}
            onClick={() => handleClick(status as TaskStatus)}
            e2eIdentifiers={["task-Status", status]}
          />
        ))}
      </Menu>
    );
  };

  return (
    <div className="pm-status-selector">
      <Popover position={Position.BOTTOM_LEFT} content={renderMenu()}>
        <Button small minimal alignText="left" icon={taskStatusIcons[currentStatus]} e2eIdentifiers={[currentStatus]}>
          <Text variant={TextVariant.Caption} clamp={1} color="var(--Tokens-text-primary)">
            {currentStatus}
          </Text>
        </Button>
      </Popover>
    </div>
  );
};

export default observer(TaskStatusSelector);
