import { Intent } from "@blueprintjs/core";

import { showToast } from "@components/UiLayers/toaster";
import { MicrosoftTeamsEventTypes, MicrosoftTeamsLinkChannelDto, MsTeamsIntegrationMetaData } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IIntegration } from "@store/IntegrationStore";
import { rollupClient } from "src/core/api";

const useMicrosoftTeamsIntegration = (integration?: IIntegration) => {
  const metadata = integration?.connection?.metadata as MsTeamsIntegrationMetaData | undefined;
  const provider = integration?.connection?.provider || "";
  const subscriptions = metadata?.teams || [];

  const linkChannel = async (channelId: string, teamId: string, entityTypes: MicrosoftTeamsEventTypes[]) => {
    const channel = { channelId, entityTypes, teamId };
    if (!provider) {
      showToast("Channel link failed, missing provider", Intent.DANGER);
      return;
    }

    try {
      const response = await rollupClient.integrations.linkMicrosoftTeamsChannel(channel);
      appStore.orgModel.updateConnectionProviderMetadata(provider, response.data.metadata);
      showToast("Successfully created channel subscription", Intent.SUCCESS);
    } catch (err) {
      console.error(err);
      showToast("Channel link failed", Intent.DANGER);
    }
  };

  const updateChannelEvents = async (teamId: string, channelId: string, entityTypes: MicrosoftTeamsEventTypes[]) => {
    if (!provider) {
      showToast("Channel link update failed, missing provider", Intent.DANGER);
      return;
    }

    try {
      const response = await rollupClient.integrations.linkMicrosoftTeamsChannel({ teamId, channelId, entityTypes });
      appStore.orgModel.updateConnectionProviderMetadata(provider, response.data.metadata);
      showToast("Successfully updated channel subscription", Intent.SUCCESS);
    } catch (err) {
      console.error(err);
      showToast("Channel update failed", Intent.DANGER);
    }
  };

  const unlinkChannel = async (subscriptionDto: MicrosoftTeamsLinkChannelDto) => {
    if (!provider) {
      showToast("Channel unlink failed, missing provider", Intent.DANGER);
      return;
    }

    try {
      const response = await rollupClient.integrations.unlinkMicrosoftTeamsChannel(subscriptionDto.teamId, subscriptionDto.channelId);
      appStore.orgModel.updateConnectionProviderMetadata(provider, response.data.metadata);
      showToast("Successfully unlinked channel", Intent.SUCCESS);
    } catch (err) {
      console.error(err);
      showToast("Channel unlink failed", Intent.DANGER);
    }
  };

  return {
    linkChannel,
    unlinkChannel,
    updateChannelEvents,
    metadata,
    subscriptions,
  };
};

export default useMicrosoftTeamsIntegration;
