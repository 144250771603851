import classNames from "classnames";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";
import { getReportById } from "@utilities/Properties";

import styles from "./ReportsTreeMinimal.module.scss";

interface IReportsTreeMinimalProps {
  reports: IReport[];
}

const getRootReport = (report: IReport): IReport => {
  if (report.parentReport) {
    return getRootReport(report.parentReport);
  }
  return report;
};

const ReportsTreeMinimal = (props: IReportsTreeMinimalProps) => {
  const { reports } = props;
  const { activeReportId } = appStore.env;
  const activeReport = activeReportId ? getReportById(activeReportId) : undefined;
  const activeRootReport = activeReport ? getRootReport(activeReport) : undefined;

  return (
    <div className={styles.reportsTreeMinimal}>
      {reports.map(report => (
        <div
          key={report.id}
          className={classNames(styles.reportsTreeMinimalReport, {
            [styles.reportsTreeMinimalReportActive]: report.id === activeRootReport?.id,
          })}
        >
          {report.displayedIcon}
        </div>
      ))}
    </div>
  );
};

export default observer(ReportsTreeMinimal);
