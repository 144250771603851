import difference from "lodash/difference";

import Image from "@components/Image";
import Attachment from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/Attachment";
import { Attachment as AttachmentModel } from "@rollup-api/models";
import { Block } from "@rollup-api/models/block";
import { Comment } from "@rollup-api/models/comments";
import {
  Transaction,
  TransactionEntity,
  TransactionPropertyDefinitionEntity,
  TransactionPropertyInstanceEntity,
  TransactionStatusInstanceEntity,
} from "@rollup-api/models/transactions";
import { PropertyDefinitionFeedFormatter } from "@router/components/BlockView/Feed/FeedFormatter/PropertyDefinitionFeedFormatter";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { EFeedActionType, EFeedEntityType, IFeed } from "@store/FeedStore";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";
import { IStatusOption } from "@store/StatusOptionStore";
import { StoreType } from "@store/types";
import { IUser } from "@store/UserStore";
import { getStatusDefinitionById } from "@utilities";
import { getMultiSelectValue, getUserValues } from "@utilities/StatusInstance";

import { PropertyInstanceFeedFormatter } from "./FeedFormatter/PropertyInstanceFeedFormatter";

import styles from "./FeedPanel.module.scss";

export const getBlockValuesDiff = (feedItem: IFeed) => {
  const newValue = feedItem.newEntity as Block;
  const oldValue = feedItem.oldEntity as Block | undefined;

  if (newValue.propertyGroups !== undefined && newValue.propertyGroups !== null) {
    const oldGroups = oldValue?.propertyGroups || [];
    return getBlockGroupsChangedString(newValue.propertyGroups, oldGroups);
  }

  if (Array.isArray(newValue.type) && Array.isArray(oldValue?.type)) {
    return getChangedString({
      newValue: newValue.type.join(", "),
      changedField: "part type",
      entityType: "block",
      oldValue: oldValue?.type?.join(", "),
    });
  }

  if (newValue.partNumber !== undefined && newValue.partNumber !== null) {
    return getChangedString({
      newValue: newValue.partNumber,
      changedField: "part number",
      entityType: "block",
      oldValue: oldValue?.partNumber,
    });
  }

  if (newValue.label !== undefined && newValue.label !== null) {
    return getChangedString({ newValue: newValue.label, changedField: "label", entityType: "block", oldValue: oldValue?.label });
  }

  if (newValue.iconData !== undefined && newValue.iconData !== null) {
    return getChangedString({
      newValue: newValue.iconData.name,
      changedField: "icon",
      entityType: "block",
      oldValue: oldValue?.iconData?.name,
    });
  }

  if (newValue.previewCustomLink !== undefined && newValue.previewCustomLink !== null) {
    return <span className={styles.feedPanelAction}>{newValue.previewCustomLink ? "changed" : "removed"} Block image</span>;
  }

  return <span className={styles.feedPanelAction}>updated Block</span>;
};

export const isPropertyInstanceEntity = (
  referenceType: EFeedEntityType,
  entity?: TransactionEntity
): entity is TransactionPropertyInstanceEntity => {
  return !!entity && referenceType === EFeedEntityType.PROPERTY_INSTANCE;
};

export const isPropertyDefinitionEntity = (
  referenceType: EFeedEntityType,
  entity?: TransactionEntity
): entity is TransactionPropertyDefinitionEntity => {
  return !!entity && referenceType === EFeedEntityType.PROPERTY_DEFINITION;
};

export const getStatusInstanceValue = (type: StatusType, value: string, statusDefinition: IStatusDefinition): string => {
  switch (type) {
    case StatusType.mention:
      return getUserValues(value)
        .map((i: IUser) => i.displayName)
        .join(", ");
    case StatusType.multiSelect:
    case StatusType.singleSelect:
      return getMultiSelectValue(statusDefinition, value)
        .map((i: IStatusOption) => i.label)
        .join(", ");
    default:
      return value;
  }
};

export const getAttachmentValuesDiff = (feedItem: IFeed) => {
  const newValue = feedItem.newEntity as AttachmentModel;
  const oldValue = feedItem.oldEntity as AttachmentModel;

  if (newValue.label !== undefined) {
    return getChangedString({
      newValue: newValue.label,
      changedField: "label",
      entityType: feedItem.entityTitle,
      oldValue: oldValue.label,
    });
  }

  return <span className={styles.feedPanelAction}>updated {feedItem.entityTitle}</span>;
};

export const getBlockActionText = (feedItem: IFeed) => {
  const newEntity = feedItem.newEntity as Block;
  const oldEntity = feedItem.oldEntity as Block | undefined;

  switch (feedItem.method) {
    case EFeedActionType.DELETE:
      return (
        <span className={styles.feedPanelAction}>
          deleted {feedItem.entityTitle}
          <span className={styles.feedPanelEntity}> {oldEntity?.label || ""}</span>
        </span>
      );
    case EFeedActionType.UPDATE:
      return getBlockValuesDiff(feedItem);
    default:
      return (
        <span className={styles.feedPanelAction}>
          {feedItem.actionTitle} {feedItem.entityTitle}
          <span className={styles.feedPanelEntity}> {newEntity.label}</span>
        </span>
      );
  }
};

export const getBlockGroupsChangedString = (newGroups: { label: string }[], oldGroups: { label: string }[]) => {
  const isDeleted = newGroups.length < oldGroups.length;
  const diffGroup = difference(newGroups, oldGroups);
  return (
    <span className={styles.feedPanelAction}>
      {isDeleted ? "Ungrouped" : "Created"}
      <span className={styles.feedPanelEntity}> {diffGroup[0].label} </span>
      property group
    </span>
  );
};

const statusInstanceCreateText = (feedItem: IFeed, statusDefinition?: IStatusDefinition) => {
  const newEntity = feedItem.newEntity as TransactionStatusInstanceEntity;
  const newValue = newEntity.value || "";
  const emptyValue = newValue === "[]" || newValue === "";
  let method = "";

  switch (feedItem.method) {
    case EFeedActionType.UPDATE:
      method = emptyValue ? "Cleared" : "Updated";
      break;
    case EFeedActionType.CREATE:
      method = "Created";
      break;
  }

  if (statusDefinition) {
    return (
      <span className={styles.feedPanelAction}>
        {method} status instance
        <span className={styles.feedPanelEntity}> {statusDefinition.label}</span>
        {!emptyValue && (
          <>
            <span> with value </span>
            <span className={styles.feedPanelEntity}>
              {getStatusInstanceValue(statusDefinition.type || StatusType.text, newValue, statusDefinition)}
            </span>
          </>
        )}
      </span>
    );
  }

  return "Created status instance";
};

export const getStatusActionText = (feedItem: IFeed) => {
  const oldEntity = feedItem.oldEntity as TransactionStatusInstanceEntity;
  const newEntity = feedItem.newEntity as TransactionStatusInstanceEntity;
  const definitionId = oldEntity.statusDefinition?.id || newEntity.statusDefinition?.id;
  const statusDefinition = getStatusDefinitionById(definitionId);

  switch (feedItem.method) {
    case EFeedActionType.DELETE:
      return (
        <span className={styles.feedPanelAction}>
          Cleared status instance
          <span className={styles.feedPanelEntity}> {statusDefinition?.label || ""}</span>
        </span>
      );
    default:
      return statusInstanceCreateText(feedItem, statusDefinition);
  }
};

export const getAttachmentActionText = (feedItem: IFeed) => {
  switch (feedItem.method) {
    case EFeedActionType.UPDATE:
      return getAttachmentValuesDiff(feedItem);
    default:
      return (
        <span className={styles.feedPanelAction}>
          {feedItem.actionTitle} {feedItem.entityTitle}
        </span>
      );
  }
};

export const renderAttachmentExtraContent = (block: IBlock, feedItem: IFeed) => {
  if (feedItem.method === EFeedActionType.CREATE) {
    const attachment = appStore.workspaceModel?.attachments.get(feedItem.referenceId);

    if (attachment) {
      return (
        <div className="feed-panel--attachment">
          <Attachment block={block} hideName attachment={attachment} hideDate hideSize />
        </div>
      );
    }
  }

  return null;
};

export const renderBlockExtraContent = (feedItem: IFeed) => {
  if (feedItem.method === EFeedActionType.UPDATE) {
    const imageUrl = (feedItem.newEntity as Block).previewCustomLink;

    if (imageUrl) {
      return (
        <div className="feed-panel--image">
          <Image imageUrl={imageUrl} width="auto" height="50" />
        </div>
      );
    }
  }

  return null;
};

export const getChangedString = (params: {
  newValue: string | number | boolean;
  changedField: string;
  entityType: string;
  oldValue?: string | number | boolean | null;
  name?: string;
}) => {
  const { newValue, changedField, entityType } = params;
  const { oldValue, name } = params;

  return (
    <span className={styles.feedPanelAction}>
      {newValue === "" ? "cleared" : "changed"} the field
      <span className={styles.feedPanelEntity}> {changedField} </span>
      of
      {name && <span className={styles.feedPanelEntity}> {name}</span>}
      &nbsp;{entityType}
      {oldValue !== undefined && oldValue !== null && (
        <>
          &nbsp;from
          <span className={styles.feedPanelEntity}> {oldValue.toString()}</span>
        </>
      )}
      {!!newValue && (
        <>
          &nbsp;to
          <span className={styles.feedPanelEntity}> {newValue.toString()}</span>
        </>
      )}
    </span>
  );
};

export const getActionText = (feedItem: IFeed) => {
  switch (feedItem.referenceType) {
    case EFeedEntityType.BLOCK:
    case EFeedEntityType.CHILD_BLOCK:
      return getBlockActionText(feedItem);
    case EFeedEntityType.PROPERTY_INSTANCE:
      return PropertyInstanceFeedFormatter.getPropertyActionText(feedItem);
    case EFeedEntityType.PROPERTY_DEFINITION:
      return PropertyDefinitionFeedFormatter.getPropertyActionText(feedItem);
    case EFeedEntityType.STATUS_INSTANCE:
      return getStatusActionText(feedItem);
    case EFeedEntityType.ATTACHMENT:
      return getAttachmentActionText(feedItem);
    case EFeedEntityType.COMMENT:
      return <span className={styles.feedPanelAction}>commented</span>;
    default:
      return "";
  }
};

export const getEditorContent = (feedItem: IFeed): string | undefined => {
  const { newEntity } = feedItem;
  if (isPropertyInstanceEntity(feedItem.referenceType, newEntity) || isPropertyDefinitionEntity(feedItem.referenceType, newEntity)) {
    return newEntity.changeJustification;
  } else if (feedItem.referenceType === EFeedEntityType.COMMENT) {
    return (newEntity as Comment).text;
  }
};

export const isValidPropertyTransaction = (
  transaction: Transaction,
  propertyInstanceId: string,
  propertyDefinitionId?: string
): boolean => {
  const { referenceId } = transaction;

  return (
    referenceId === propertyInstanceId || referenceId === propertyDefinitionId || isPropertyInstanceComment(transaction, propertyInstanceId)
  );
};

export const isPropertyInstanceComment = (transaction: Transaction, propertyInstanceId: string): boolean => {
  return (
    transaction.referenceType === EFeedEntityType.COMMENT &&
    transaction.newEntity.parentType === StoreType.PropertyInstance &&
    transaction.newEntity.parentId === propertyInstanceId
  );
};
