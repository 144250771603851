import { Divider, Position } from "@blueprintjs/core";

import { Button } from "@components/Button";
import HelpMenu from "@components/HelpMenu/HelpMenu";
import { Popover } from "@components/Popover";

interface IHelpMenuButtonProps {
  dividerClassName?: string;
}

const HelpMenuButton = (props: IHelpMenuButtonProps) => {
  return (
    <>
      <Popover
        content={<HelpMenu />}
        captureDismiss
        position={Position.TOP_RIGHT} // Explicit positioning resolves browser compatibility. Default: "auto"
      >
        <Button icon="help" minimal text="Help" e2eIdentifiers="help" />
      </Popover>
      <Divider className={props.dividerClassName} />
    </>
  );
};

export default HelpMenuButton;
