import { Intent } from "@blueprintjs/core";
import { DataSourceQOFactory } from "@queries/DataSourceQOFactory";
import { generateAdditionalUnits } from "@rollup-io/engineering";
import Cookies from "js-cookie";
import { action, flow as mobxFlow, observable, reaction } from "mobx";
import makeInspectable from "mobx-devtools-mst";
import { addDisposer, destroy, flow, getSnapshot, Instance, types } from "mobx-state-tree";
import { persistInLocalStorage } from "mobx-state-tree-localstorage";

import { CreateCatalogItemDialogType } from "@components/CreateCatalogItemDialog/types";
import { DialogExtendBomTableType } from "@components/Dialog/DialogExtendBomTable/DialogExtendBomTable";
import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { isVercel } from "@rollup-api/api/auth";
import { AnalyticsRole, TokenError, UserPermission, UserRole } from "@rollup-api/api/authTypes";
import { Workspace } from "@rollup-api/models";
import { UpdateCatalogItemVersionDto } from "@rollup-api/models/catalogItem/catalogItemDtos";
import { ProfilesUpdateDto } from "@rollup-api/models/profiles";
import { ProjectManagementModuleStore } from "@store/ProjectManagement/ProjectManagementModuleStore";
import { BlockSidePanel } from "@store/SidePanelStore";
import { EntityType } from "@store/types";
import { currencyUnits, delay, filterEnumArray, getBlockById, resolveAmongAllEntities, setIntervalAsync } from "@utilities";
import { rollupClient } from "src/core/api";
import {
  IPresenceResponse,
  MockRealtimeService,
  PresenceInfo,
  PresenceState,
  RealtimeService,
  WebsocketRealtimeService,
} from "src/services/RealtimeService";
import { IRevisionListItem, IWorkspaceListItem, WorkspaceService } from "src/services/WorkspaceService";

import { setDataDogUser } from "../lib/Datadog";
import { queryClient } from "../lib/ReactQuery/queryClient";
import { setSegmentUser, trackSegmentEvent } from "../lib/Segment";

import { IBomTable } from "./BomTable/BomTableStore";
import { EnvironmentStore } from "./EnvironmentStore";
import { FeedbackStore } from "./FeedbackStore";
import { InviteNewUserStore } from "./InviteNewUserStore";
import { OrganizationStore } from "./OrganizationStore";
import { RollupEventsStore } from "./RollupEventsStore";
import { SettingsStore } from "./SettingsStore";
import { RollupEventEvents, UserSettingsStore } from "./UserSettingsStore";
import { IUser, UserStore } from "./UserStore";
import { IWorkspace, IWorkspaceSnapshotIn, IWorkspaceSnapshotOut, SavingStatus, WorkspaceStore } from "./WorkspaceStore";

generateAdditionalUnits(currencyUnits);

let realtimeService: RealtimeService;
if (import.meta.env.VITE_NODE_ENV === "test") {
  realtimeService = new MockRealtimeService();
} else {
  realtimeService = new WebsocketRealtimeService();
}

// Update presence with a delay of 10 seconds
setIntervalAsync(async () => await appStore.updatePresence(), 10000);

export enum WorkspaceSyncState {
  Idle = "idle",
  Saving = "saving",
  Saved = "saved",
  Error = "error",
  Loading = "loading",
}

class AppUiStore {
  @observable accessor selectedAttachmentId = "";
  @observable accessor syncState = WorkspaceSyncState.Idle;
  @observable accessor presenceData: PresenceInfo[] = [];
  @observable accessor selectedIds: string[] = [];
  @observable accessor selectedCatalogItemIds: string[] = [];
  @observable accessor selectedCatalogItemReferenceIds: string[] = [];
  @observable accessor showDeleteCatalogItemsModal = false;
  @observable accessor isPrintingPage = false;
  @observable accessor latencyMeasurement = NaN;
  @observable accessor commandPaletteEnabled = false;
  @observable accessor catalogItemCreationModalTab: CreateCatalogItemDialogType | undefined;
  @observable accessor vcsDialogEnabled = false;
  @observable accessor workspaceDeleteConfirmationDialog: { idList: string[]; label: string } | null = null;
  @observable accessor createReportTemplateDialog = "";
  @observable accessor catalogItemPreviewId = "";
  @observable accessor catalogItemReferencePreviewId = "";
  @observable accessor showPdmThumbnailForIds: string[] = [];
  @observable accessor showPdmFlatten = true;
  @observable accessor deleteCatalogItemId = "";
  @observable accessor deleteCatalogItemReferenceId = "";
  @observable accessor attachmentDetails = "";
  @observable accessor reportTemplatesDialog = false;
  @observable accessor reviewImportId = "";
  @observable accessor deleteReportConfirmationDialogEnabled = false;
  @observable accessor developerDialogEnabled = false;
  @observable accessor hoopsDebugDialogEnabled = false;
  @observable accessor showAddNewWorkspaceDialog = false;
  @observable accessor editBomTableDialogEnabled = false;
  @observable accessor editingBomTableId: string | undefined;
  @observable accessor extendBomTableDialog: DialogExtendBomTableType | undefined;
  @observable accessor signUpDialogEnabled = false;
  @observable accessor uploadQueueWindowEnabled = false;
  @observable accessor hoopsTreeWidth = 0;
  @observable accessor pdmNewVersionDialog = ""; // catalog item id
  @observable accessor showPdmAnnotateNewVersionDialog = false;
  @observable accessor pdmItemVersionProgress: (UpdateCatalogItemVersionDto & { catalogItemId: string }) | null = null;
  @observable accessor cadFileUploadData: { fileList: FileList; blockId?: string } | null = null;
  @observable accessor hoveredCommentAnnotationId = "";
  @observable accessor activeCommentPopoverEntityId = "";
  @observable accessor colIdToBeScrolledTo: string = "";

  @mobxFlow.bound
  public *setSyncState(newState: WorkspaceSyncState) {
    if (this.syncState === newState) {
      return;
    }

    this.syncState = newState;
    if (newState === WorkspaceSyncState.Saved || newState === WorkspaceSyncState.Error) {
      yield delay(3000);
      // Handle edge case where state has been changed during delay
      if (this.syncState === newState) {
        this.syncState = WorkspaceSyncState.Idle;
      }
    }
  }

  @action.bound
  public setHoopsTreeWidth(value: number) {
    this.hoopsTreeWidth = value;
  }

  @action.bound
  public setCreateReportTemplateDialog(id = "") {
    this.createReportTemplateDialog = id;
  }

  @action.bound
  public setAttachmentDetails(id = "") {
    this.attachmentDetails = id;
  }

  @action.bound
  public toggleDeleteCatalogItemsModal() {
    this.showDeleteCatalogItemsModal = !this.showDeleteCatalogItemsModal;
  }

  @action.bound
  public hideDeleteCatalogItemsModal() {
    this.showDeleteCatalogItemsModal = false;
  }

  @action.bound
  public setSelectedCatalogItemIds(ids: string[]) {
    this.selectedCatalogItemIds = ids;
  }

  @action.bound
  public setSelectedCatalogItemReferenceIds(ids: string[]) {
    this.selectedCatalogItemReferenceIds = ids;
  }

  @action.bound
  public toggleSelectedCatalogItemId(id: string) {
    if (this.selectedCatalogItemIds.includes(id)) {
      this.selectedCatalogItemIds = this.selectedCatalogItemIds.filter(i => i !== id);
    } else {
      this.selectedCatalogItemIds.push(id);
    }
  }

  @action.bound
  public toggleSelectedCatalogItemReferenceId(id: string) {
    if (this.selectedCatalogItemReferenceIds.includes(id)) {
      this.selectedCatalogItemReferenceIds = this.selectedCatalogItemReferenceIds.filter(i => i !== id);
    } else {
      this.selectedCatalogItemReferenceIds.push(id);
    }
  }

  @action.bound
  public setPresenceData(presenceInfo: PresenceInfo[]) {
    this.presenceData = presenceInfo;
  }

  @action.bound
  public setSelectedIds(selectedIds: string[]) {
    this.selectedIds = selectedIds;
  }

  @action.bound
  public setLatencyInformation(latency: number) {
    this.latencyMeasurement = latency;
  }

  @action.bound
  public setSelectedAttachmentId(id: string) {
    this.selectedAttachmentId = id;
  }

  @action.bound
  public showCommandPalette() {
    this.commandPaletteEnabled = true;
  }

  @action.bound
  public hideCommandPalette() {
    this.commandPaletteEnabled = false;
  }

  @action.bound
  public setCatalogItemCreationModalTab(tab: CreateCatalogItemDialogType | undefined) {
    this.catalogItemCreationModalTab = tab;
  }

  @action.bound
  public toggleShowPdmThumbnailForId(id: string) {
    if (this.showPdmThumbnailForIds.includes(id)) {
      this.showPdmThumbnailForIds = this.showPdmThumbnailForIds.filter(i => i !== id);
    } else {
      this.showPdmThumbnailForIds.push(id);
    }
  }

  @action.bound
  public toggleShowPdmFlatten() {
    this.showPdmFlatten = !this.showPdmFlatten;
  }

  @action.bound
  public setIsPrintingPage(printing: boolean) {
    document.body.classList.toggle("printing", printing);
    this.isPrintingPage = printing;
  }

  @action.bound
  public setShowPdmAnnotateNewVersionDialog() {
    this.showPdmAnnotateNewVersionDialog = true;
  }

  @action.bound
  public hidePdmAnnotateNewVersionDialog() {
    this.showPdmAnnotateNewVersionDialog = false;
  }

  @action.bound
  public setShowPdmNewVersionDialog(id: string) {
    this.pdmNewVersionDialog = id;
  }

  @action.bound
  public hidePdmNewVersionDialog() {
    this.pdmNewVersionDialog = "";
  }

  @action.bound
  public hideCatalogItemCreationModal() {
    this.catalogItemCreationModalTab = undefined;
    this.cadFileUploadData = null;
  }

  @action.bound
  public updatePdmItemVersionProgress(versionChanges: (UpdateCatalogItemVersionDto & { catalogItemId: string }) | null) {
    this.pdmItemVersionProgress = versionChanges;
  }

  @action.bound
  public setReviewImportId(id: string) {
    this.reviewImportId = id;
  }

  @action.bound
  public setCatalogItemPreviewId(id = "") {
    this.catalogItemPreviewId = id;
  }

  @action.bound
  public setCatalogItemReferencePreviewId(id = "") {
    this.catalogItemReferencePreviewId = id;
  }

  @action.bound
  public resetCatalogItemReferencePreviewId() {
    this.catalogItemReferencePreviewId = "";
  }

  @action.bound
  public resetCatalogItemPreviewId() {
    this.catalogItemPreviewId = "";
  }

  @action.bound
  public setCadFileUploadData(data: { fileList: FileList; blockId?: string } | null) {
    this.cadFileUploadData = data;
  }

  @action.bound
  public hideAddNewCadFileDialog() {
    this.cadFileUploadData = null;
  }

  @action.bound
  public showDeleteConfirmationDialog(data: { idList: string[]; label: string }) {
    this.workspaceDeleteConfirmationDialog = data;
  }

  @action.bound
  public hideDeleteConfirmationDialog() {
    this.workspaceDeleteConfirmationDialog = null;
  }

  @action.bound
  public showReportTemplatesDialog() {
    this.reportTemplatesDialog = true;
  }

  @action.bound
  public hideReportTemplatesDialog() {
    this.reportTemplatesDialog = false;
  }

  @action.bound
  public showDeleteReportConfirmationDialog() {
    this.deleteReportConfirmationDialogEnabled = true;
  }

  @action.bound
  public hideDeleteReportConfirmationDialog() {
    this.deleteReportConfirmationDialogEnabled = false;
  }

  @action.bound
  public showDeveloperDialog() {
    this.developerDialogEnabled = true;
  }

  @action.bound
  public hideDeveloperDialog() {
    this.developerDialogEnabled = false;
  }

  @action.bound
  public toggleHoopsDebugDialog() {
    this.hoopsDebugDialogEnabled = !this.hoopsDebugDialogEnabled;
  }

  @action.bound
  public hideHoopsDebugDialog() {
    this.hoopsDebugDialogEnabled = false;
  }

  @action.bound
  public toggleAddNewWorkspaceDialog() {
    this.showAddNewWorkspaceDialog = !this.showAddNewWorkspaceDialog;
  }

  @action.bound
  public showEditBomTableDialog(existingTable?: IBomTable) {
    this.editingBomTableId = existingTable?.id;
    this.editBomTableDialogEnabled = true;
  }

  @action.bound
  public setExtendBomTableDialog(type?: DialogExtendBomTableType) {
    this.extendBomTableDialog = type;
  }

  @action.bound
  public hideEditBomTableDialog() {
    this.editingBomTableId = undefined;
    this.editBomTableDialogEnabled = false;
  }

  @action.bound
  public showSignUpDialog() {
    this.signUpDialogEnabled = true;
  }

  @action.bound
  public hideSignUpDialog() {
    this.signUpDialogEnabled = false;
  }

  @action.bound
  public hideUploadQueueWindow() {
    this.uploadQueueWindowEnabled = false;
  }

  @action.bound
  public showUploadQueueWindow() {
    this.uploadQueueWindowEnabled = true;
  }

  @action.bound
  public setDeleteCatalogItem(id: string) {
    this.deleteCatalogItemId = id;
  }

  @action.bound
  public setDeleteCatalogItemReference(id: string) {
    this.deleteCatalogItemReferenceId = id;
  }

  @action.bound
  public setHoveredCommentAnnotationId(id = "") {
    this.hoveredCommentAnnotationId = id;
  }

  @action.bound
  public setActiveCommentPopoverEntityId(id = "") {
    this.activeCommentPopoverEntityId = id;
  }

  @action.bound
  public setColIdToBeScrolledTo(id: string) {
    this.colIdToBeScrolledTo = id;
  }
}

export enum AuthStatus {
  Pending,
  FetchingProfile,
  LoggedIn,
  LoggedOut,
}

const AppStore = types
  .model("AppStore", {
    env: EnvironmentStore,
    workspaceModel: types.maybeNull(WorkspaceStore),
    temporaryWorkspace: types.maybeNull(WorkspaceStore),
    settingsModel: types.maybeNull(SettingsStore),
    orgModel: types.optional(OrganizationStore, {}),
    userModel: types.maybeNull(UserStore),
    projectManagement: types.optional(ProjectManagementModuleStore, {}),
  })
  .volatile(() => ({
    ui: new AppUiStore(),
    feedback: FeedbackStore.create(),
    inviteNewUser: InviteNewUserStore.create(),
    realtimeService: realtimeService,
    authenticationPending: true,
    authCheckHandle: undefined as any,
    accessErrorMessage: TokenError.None,
  }))
  .actions(self => ({
    setUserProfile(profile: IUser) {
      self.userModel = UserStore.create(profile);
    },
    login() {
      if (import.meta.env.VITE_NODE_ENV !== "test") {
        const params = new URLSearchParams(window.location.search);
        const domainFromHost = window.location.host.split(".")[0];
        const domainParam = params.get("domain");
        const domain = domainParam || domainFromHost;
        params.delete("domain");
        const paramsStr = params.size ? `?${params.toString()}` : "";
        const redirectUrl = encodeURI(`${window.location.pathname}${paramsStr}`) ?? "";
        window.location.href = `${import.meta.env.VITE_AUTH}/?domain=${domain}&redirectUrl=${redirectUrl}`;
      }
    },
  }))
  .actions(self => ({
    pullOrgWorkspaces: flow(function* pullOrgWorkspaces(): Generator<any, boolean, IWorkspaceListItem[] | undefined> {
      const workspaces = yield WorkspaceService.ListWorkspaces();
      if (!workspaces) {
        showToast("Error listing workspaces.", "danger", "info-sign");
        return false;
      }

      self.orgModel.setWorkspacesList(workspaces);
      return true;
    }),
  }))
  .actions(self => {
    return {
      updatePresence: flow(function* updatePresences(): Generator<Promise<IPresenceResponse>, void, IPresenceResponse> {
        let entityId = "";
        let entityType = EntityType.None;
        if (self.env.activeReportBlockId) {
          entityId = self.env.activeReportBlockId;
          entityType = EntityType.ReportBlock;
        } else if (self.env.activeReportId) {
          entityId = self.env.activeReportId;
          entityType = EntityType.Report;
        } else if (self.env.activePropertyInstance) {
          entityId = self.env.activePropertyInstance.id;
          entityType = EntityType.PropertyInstance;
        } else if (self.env.activeBlock) {
          entityId = self.env.activeBlock.id;
          entityType = EntityType.Block;
        } else if (self.env.activeAnalysisId) {
          entityId = self.env.activeAnalysisId;
          entityType = EntityType.CodeBlock;
        }

        const result = yield realtimeService.updatePresence(appStore.workspaceModel?.id, entityId, entityType, PresenceState.Active);
        self.ui.setPresenceData(result.users);
        self.ui.setLatencyInformation(result.latency);
      }),
      setWorkspace(workspace: IWorkspace) {
        if (!workspace || workspace === self.workspaceModel) {
          return;
        }

        if (self.workspaceModel) {
          realtimeService.unsubscribeFromChanges(`workspace/${self.workspaceModel.id}`);
          realtimeService.unsubscribeFromChanges(`workspace/${workspace.id}/transaction`);
          self.env.clearPinnedProperties();
        }
        realtimeService.subscribeToChanges(`workspace/${workspace.id}`);
        realtimeService.subscribeToChanges(`workspace/${workspace.id}/transaction`);
        rollupClient.setWorkspaceId(workspace.id);
        trackSegmentEvent("workspace:load", {
          id: workspace.id,
          label: workspace.label,
          numBlocks: workspace.blockMap?.size ?? 0,
        });
        self.workspaceModel = workspace;
        this.updatePresence();
        WorkspaceService.SetRecentWorkspace(workspace.id).then(res => {
          if (!res) {
            showToast("Error setting most recent workspace", Intent.WARNING, "error");
          }
        });
      },
      setTemporaryWorkspace(workspace: IWorkspace): IWorkspace {
        self.temporaryWorkspace = workspace;
        return self.temporaryWorkspace;
      },
      nullOutSettings() {
        self.env.clearPinnedProperties();
        self.env.clearActiveBlock();
        self.env.clearActiveReport();
        self.env.clearActiveReportBlock();
        self.env.clearActiveBomTable();
        if (self.workspaceModel) {
          realtimeService.unsubscribeFromChanges(`workspace/${self.workspaceModel.id}`);
        }
        self.workspaceModel = null;
      },
      logout() {
        rollupClient.auth.clearToken();
        if (self.userModel) {
          destroy(self.userModel);
        }

        if (import.meta.env.VITE_NODE_ENV !== "test") {
          setTimeout(() => {
            trackSegmentEvent("user:logout");
            const logoutUrl = `${import.meta.env.VITE_AUTH}/logout`;
            console.debug(`Redirecting to logout URL ${logoutUrl}`);
            window.location.href = logoutUrl;
          }, 100);
        }
      },
    };
  })
  .actions(self => ({
    pushOrgChanges: flow(function* pushOrgInfoChanges(dto: {
      name?: string;
      description?: string;
      allowedDomains?: string[];
    }): Generator<any, boolean, any> {
      try {
        const { data, status } = yield rollupClient.organizations.update(dto);
        if (data && status === 200) {
          showToast("Organization info updated", "success");
          if (dto.name) {
            self.orgModel.info.setName(dto.name);
          }
          if (dto.description) {
            self.orgModel.info.setDescription(dto.description);
          }
          if (dto.allowedDomains) {
            self.orgModel.info.setAllowedDomains(dto.allowedDomains);
          }
          return true;
        }
      } catch (error) {
        console.warn("Error updating organization", error);
        showApiErrorToast("Could not update organization info", new Error("400 error"));
      }
      return false;
    }),
    pushProfile: flow(function* pushProfile(profilesUpdateDto: ProfilesUpdateDto): Generator<any, boolean | null, any> {
      try {
        const res = yield rollupClient.profiles.updateProfile(profilesUpdateDto);
        if (!res) {
          console.warn("Error updating profile");
          showToast("Error updating profile parameters", "danger");
          return false;
        } else {
          showToast("Profile updated", "success");
          // Update user model
          self.userModel?.setName(profilesUpdateDto.name ?? self.userModel?.name ?? "");
          self.userModel?.setJobTitle(profilesUpdateDto.jobTitle ?? self.userModel?.jobTitle ?? "");
          self.userModel?.setDepartment(profilesUpdateDto.department ?? self.userModel?.department ?? "");
          return true;
        }
      } catch (error) {
        console.warn("Error updating profile", error);
        showToast("Error updating profile parameters", "danger");
        return false;
      }
    }),
    switchOrganization: flow(function* (orgId: string, newSlug: string): Generator<any, boolean, any> {
      if (self.orgModel?.info?.id === orgId) {
        return false;
      }

      try {
        const res = yield rollupClient.auth.exchangeSession(orgId);
        if (res?.success) {
          window.location.replace(window.location.origin.replace(self.orgModel?.info?.slug ?? "app", newSlug));
          return true;
        }
      } catch (err) {
        console.error(err);
      }
      return false;
    }),
    executeNodeEngine: flow(function* executeNodeEngine(): Generator<any, void, any> {
      // Open the run dialog
      self.env.setDebugDrawerIsOpen(true);
      try {
        //  Create the job payload
        const raw = JSON.stringify({ workspaceModel: self.workspaceModel });

        const res = yield fetch("https://2oa112akei.execute-api.us-east-1.amazonaws.com/default/node-engine/", {
          method: "POST",
          headers: {
            "x-api-key": "1RWpwZMJZj13q02REazeRqzMQ9PHqI5JVhLHJm70",
            "content-type": "application/json",
          },
          body: raw,
        });

        self.workspaceModel?.setLastExecutionError("");
        self.workspaceModel?.setLastExecutionLog("");
        self.workspaceModel?.setLastExecutionResult("");
        // Check if response has errors
        if (res.ok) {
          const json = res.json();
          showToast("Dispatch Success", "success");
          if (json.errorMessage) {
            showToast("JSON.errorMessage running node engine", "danger");
            self.workspaceModel?.setLastExecutionError(JSON.stringify(json.error, null, 2));
            self.workspaceModel?.setLastExecutionResult(JSON.stringify(json.executionLog, null, 2));
          } else {
            showToast("JSON No Error running node engine", "none");
            self.workspaceModel?.setLastExecutionResult(JSON.stringify(json.executionLog, null, 2));
          }
        } else {
          console.error("Has Errors: " + JSON.stringify(JSON.stringify(res), null, 1));
        }
      } catch (err: any) {
        if (err.executionLog) {
          self.workspaceModel?.setLastExecutionLog(JSON.stringify(err.executionLog.join(" \n ").replace(/(^"|"$)/g, ""), null, 2));
        }
        self.workspaceModel?.setLastExecutionError(JSON.stringify(err.error, null, 2));
        console.error("Validation Error: " + JSON.stringify(err, null, 2));
      }
    }),
    loadWorkspaceFromDatabase: flow(function* loadWorkspaceFromDatabase(
      workspaceId: string,
      silentReload = false
    ): Generator<any, void, any> {
      if (workspaceId === self.workspaceModel?.id) {
        return;
      }
      try {
        self.nullOutSettings();
        self.ui.setSyncState(WorkspaceSyncState.Loading);
        if (self.workspaceModel) {
          console.debug(`Clearing existing workspace ${self.workspaceModel.id}`);
        }
        const workspace = yield WorkspaceService.FetchWorkspace(workspaceId);
        if (!workspace) {
          showToast("Couldn't load workspace", "danger", "info-sign");
          self.ui.setSyncState(WorkspaceSyncState.Error);
        } else {
          // check the temporary workspace to see if it's the same as the one we're loading
          // and if yes, clear that to prevent collisions in MST
          if (self.temporaryWorkspace?.id === workspaceId) {
            self.temporaryWorkspace = null;
          }
          self.setWorkspace(workspace);
          appStore.env.addRecentWorkspace({
            id: workspace.id,
            label: workspace.label,
            updatedAt: workspace.updatedAt,
            createdAt: workspace.createdAt,
            createdBy: workspace.createdBy,
          });
          self.ui.setSyncState(WorkspaceSyncState.Idle);
          if (!silentReload) {
            showToast("Workspace loaded", "success");
          }
        }
      } catch (e) {
        console.error(e);
        self.workspaceModel?.ui?.setCurrentAction(SavingStatus.saveFailed);
        showToast("Error switching workspaces", "danger", "info-sign");
        self.ui.setSyncState(WorkspaceSyncState.Error);
      }
    }),
    loadTemporaryWorkspaceFromDatabase: flow(function* loadTemporaryWorkspaceFromDatabase(
      workspaceId: string
    ): Generator<any, IWorkspace | undefined, any> {
      // only load the workspace as temporary if it's different from the current workspace
      // to prevent collisions in MST
      if (self.workspaceModel?.id === workspaceId) {
        // Won't load the current workspace again as temporary
        return self.workspaceModel;
      }
      if (self.temporaryWorkspace?.id === workspaceId) {
        // Won't load the same temporary workspace again
        return self.temporaryWorkspace;
      }
      try {
        // Trying to load workspace ${workspaceId} as temporary
        const temporaryWorkspace = yield WorkspaceService.FetchWorkspace(workspaceId);
        if (temporaryWorkspace) {
          // Workspace ${workspaceId} loaded as temporary
          return self.setTemporaryWorkspace(temporaryWorkspace);
        } else {
          console.error("Couldn't load temporary workspace");
          return;
        }
      } catch (e) {
        console.error("Couldn't load temporary workspace", e);
      }
    }),
    setNewWorkspaceFromExternal: flow(function* setNewWorkspaceFromExternal(json?: IWorkspaceSnapshotIn): Generator<any, string, any> {
      // TODO: do some client-side validation as well
      if (!json) {
        showToast("External workspace JSON is not valid", "danger", "info-sign");
        console.debug(json);
        return "";
      }

      const newWorkspace = yield WorkspaceService.ImportWorkspace(json);
      if (newWorkspace) {
        self.orgModel.addWorkspaceToList({
          id: newWorkspace.id,
          label: newWorkspace.label,
          updatedAt: newWorkspace.updatedAt,
          createdAt: newWorkspace.createdAt,
          createdBy: newWorkspace.createdBy,
        });
        self.setWorkspace(newWorkspace);
        return newWorkspace.id;
      } else {
        showToast("Error importing workspace", "danger", "error");
        return "";
      }
    }),
    deleteWorkspaces: flow(function* deleteWorkspaces(workspaceIds: string[]): Generator<any, void, any> {
      if (!workspaceIds.length) {
        return;
      }

      if (self.workspaceModel?.id && workspaceIds.includes(self.workspaceModel.id)) {
        self.nullOutSettings();
      }

      try {
        const deleteResponse = yield rollupClient.workspaces.deleteMany(workspaceIds);
        const deletedWorkspaces = deleteResponse?.data?.ids ?? [];
        for (const id of deletedWorkspaces) {
          self.orgModel.removeWorkspaceFromList(id);
          self.env.removeRecentWorkspace(id);
        }
        showToast(`${deletedWorkspaces > 1 ? `${deletedWorkspaces} workspaces` : "Workspace"} deleted successfully.`, Intent.SUCCESS);
      } catch (error) {
        console.error(error);
        showToast("Error deleting workspaces", "danger", "error");
      }
      yield self.pullOrgWorkspaces();
    }),
  }))
  .actions(self => ({
    applyRevision: flow(function* applyRevision(revision: IRevisionListItem): Generator<any, boolean, any> {
      if (!self.workspaceModel || revision.workspaceId !== self.workspaceModel.id) {
        showToast("Invalid snapshot selected", Intent.WARNING, "error");
        return false;
      }
      if (yield WorkspaceService.ApplyRevision(revision.id)) {
        showToast("Workspace restored", Intent.SUCCESS);
        // Realtime workspaces will be sent a reload command automatically
        yield self.loadWorkspaceFromDatabase(revision.workspaceId);
        return true;
      } else {
        showToast("Error restoring selected snapshot", Intent.WARNING, "error");
        return false;
      }
    }),
    createRevision: flow(function* createRevision(label: string, description?: string): Generator<any, boolean, any> {
      if (!self.workspaceModel) {
        return false;
      }
      if (yield WorkspaceService.CreateRevision(self.workspaceModel.id, label, description)) {
        showToast("Workspace snapshot created", Intent.SUCCESS);
        return true;
      } else {
        showToast("Error creating snapshot", Intent.WARNING, "error");
        return false;
      }
    }),
    deleteRevision: flow(function* deleteRevision(revision: IRevisionListItem): Generator<any, boolean, any> {
      if (!self.workspaceModel || revision.workspaceId !== self.workspaceModel.id) {
        showToast("Invalid snapshot selected", Intent.WARNING, "error");
        return false;
      }
      if (yield WorkspaceService.DeleteRevision(revision.id)) {
        showToast("Snapshot deleted", Intent.SUCCESS);
        return true;
      } else {
        showToast("Error deleting selected snapshot", Intent.WARNING, "error");
        return false;
      }
    }),
    duplicateWorkspace: flow(function* duplicateWorkspace(id?: string): Generator<any, string | undefined, any> {
      const duplicatedId = id || self.workspaceModel?.id;
      if (!duplicatedId) {
        return undefined;
      }
      const res: { data?: Workspace; status: number } = yield rollupClient.workspaces.copy(duplicatedId);
      if (res?.status !== 201 || !res?.data?.id) {
        showToast("Error duplicating workspace", "danger", "info-sign");
      } else {
        const workspace = res.data;
        self.orgModel.addWorkspaceToList({
          id: workspace.id,
          label: workspace.label,
          updatedAt: workspace.updatedAt,
          createdAt: workspace.createdAt,
          createdBy: workspace.createdBy,
        });
      }
      return res.data?.id;
    }),
    subscribeToOrganizationChanges: () => {
      realtimeService.subscribeToChanges(`organization/${self.orgModel.info.id}`);
      addDisposer(self, () => realtimeService.unsubscribeFromChanges(`organization/${self.orgModel.info.id}`));
    },
  }))
  .actions(self => ({
    afterCreate: flow(function* (): Generator<any, void, any> {
      const urlParams = new URLSearchParams(window.location.search);
      const forceUpdateAccess = urlParams.get("forceUpdateAccess");

      if (forceUpdateAccess) {
        Cookies.remove("access_token");
        rollupClient.auth.clearToken();
      }

      const disposer = reaction(
        () => self.workspaceModel,
        () => {
          if (self.workspaceModel) {
            // Example using DataSourceQOFactory for making a request from an MST store
            queryClient.fetchQuery(DataSourceQOFactory.createGetQO(self.workspaceModel.id)).then(res => console.debug(res));
          }
        }
      );
      addDisposer(self, disposer);
      try {
        const tokenResult = yield rollupClient.auth.refreshAccessToken();
        if (!tokenResult?.success) {
          self.userModel = null;
          self.accessErrorMessage = tokenResult?.message ?? TokenError.Unknown;
        } else {
          self.accessErrorMessage = TokenError.None;
          console.debug("Token was successfully refreshed");
        }

        yield self.orgModel.loadOrganization();
        self.subscribeToOrganizationChanges();

        self.authenticationPending = false;
        const tokenClaims = rollupClient.auth.tokenClaims;

        const userInfo = yield rollupClient.profiles.retrieveProfile();
        if (!userInfo?.data?.id) {
          showApiErrorToast("Error fetching user data", new Error());
          self.userModel = null;
          self.accessErrorMessage = TokenError.Unknown;
          return;
        }

        const hostname = window.location.hostname;
        if (
          !tokenClaims?.slug ||
          (hostname !== "localhost" && !isVercel() && !hostname?.toLowerCase()?.startsWith(`${tokenClaims.slug.toLowerCase()}.`))
        ) {
          self.userModel = null;
          self.accessErrorMessage = TokenError.IncorrectOrg;
          console.error(`Currently logged into ${tokenClaims?.slug}, does not match hostname ${hostname}`);
          return;
        }

        userInfo.data.permissions = filterEnumArray(UserPermission, tokenClaims?.permissions);
        userInfo.data.orgId = tokenClaims?.orgId;
        userInfo.data.roles = filterEnumArray(UserRole, userInfo.data.roles);
        userInfo.data.hasPassword = !!userInfo.data.hasPassword;

        self.settingsModel = SettingsStore.create({});

        const userProfile: IUser = structuredClone(userInfo.data);

        // TODO!: Placeholder rollupEvents settings. Remove once we have user settings being returned from the API
        userProfile.settings = UserSettingsStore.create({
          rollupEventSettings: {
            emailRollupEvents: [RollupEventEvents.MENTIONED_IN_COMMENT, RollupEventEvents.COMMENT_REPLIED],
            desktopRollupEvents: [RollupEventEvents.COMMENT_REPLIED, RollupEventEvents.MENTIONED_IN_REPORT],
            slackRollupEvents: [RollupEventEvents.MENTIONED_IN_COMMENT],
          },
        });

        userProfile.rollupEvents = RollupEventsStore.create({
          rollupEvents: [],
          topRollupEventsTotal: [],
          unreadInboxRollupEventCount: 0,
          readInboxRollupEventCount: 0,
          unreadArchiveRollupEventCount: 0,
          readArchiveRollupEventCount: 0,
        });

        self.setUserProfile(userProfile);

        // Fetch secondary organization information
        yield Promise.all([self.pullOrgWorkspaces(), self.orgModel.fetchIntegrations()]);

        if (import.meta.env.VITE_NODE_ENV !== "test" && self.userModel) {
          setDataDogUser({
            id: self.userModel.id,
            name: self.userModel.displayName ?? undefined,
            email: self.userModel.email ?? undefined,
            role: self.userModel.roles?.includes(UserRole.Admin || UserRole.Owner) ? AnalyticsRole.Admin : AnalyticsRole.User,
            orgId: self.orgModel?.info?.id,
            orgName: self.orgModel?.info?.slug,
          });
          setSegmentUser({
            id: self.userModel.id,
            name: self.userModel.displayName ?? undefined,
            email: self.userModel.email ?? undefined,
            role: self.userModel.isAdmin ? AnalyticsRole.Admin : AnalyticsRole.User,
            orgId: self.orgModel?.info?.id,
            orgName: self.orgModel?.info?.slug ?? undefined,
            domain: self.orgModel?.info?.allowedDomains?.[0] ?? undefined,
          });
        }
      } catch (error) {
        console.warn(error);
        self.authenticationPending = false;
        self.userModel = null;
        return;
      }
    }),
  }))
  .views(self => ({
    get workspaceSnapshot(): IWorkspaceSnapshotOut | Record<string, never> {
      if (appStore.workspaceModel) {
        return getSnapshot(appStore.workspaceModel) as IWorkspaceSnapshotOut;
      } else {
        return {};
      }
    },
    get authStatus(): AuthStatus {
      if (self.authenticationPending) {
        return AuthStatus.Pending;
      } else if (self.userModel?.id && self.orgModel?.info?.id) {
        return AuthStatus.LoggedIn;
      }
      return self.accessErrorMessage === TokenError.None ? AuthStatus.FetchingProfile : AuthStatus.LoggedOut;
    },
    get workspaceViewers() {
      if (!self.ui.presenceData?.length || !self.orgModel?.info?.orgMembers?.length) {
        return [];
      }
      const viewers = [];

      const getParentBlock = (entity: ReturnType<typeof resolveAmongAllEntities>) => {
        if (entity && "parentBlockId" in entity && entity.parentBlockId) {
          return getBlockById(entity.parentBlockId);
        }
      };

      for (const info of self.ui.presenceData) {
        if (info.clientId === self.realtimeService.clientId) {
          continue;
        }
        const user = self.orgModel.info.orgMembers.find(u => u.id === info.userId);
        if (user) {
          const entity = resolveAmongAllEntities(info.entityId, info.entityType);
          const block = getParentBlock(entity);

          viewers.push({
            clientId: info.clientId,
            id: info.userId,
            displayName: user.displayName,
            entityId: info.entityId,
            entityType: info.entityType,
            state: info.state,
            picture: user.avatarUrl,
            block,
            entity,
          });
        }
      }
      viewers.sort((a, b) => (a.clientId > b.clientId ? 1 : -1));
      return viewers;
    },
    isUserOnline(userId: string) {
      return self.ui.presenceData.some(p => p.userId === userId);
    },
  }));

export type IAppStore = Instance<typeof AppStore>;

const envStore = persistInLocalStorage({
  tree: EnvironmentStore,
  id: "@Rollup/env",
  initialState: {
    themeIsDark: true,
    leftSidebarIsCollapsed: false,
    rightSidebarIsCollapsed: true,
    pinnedSidePanels: [BlockSidePanel.Programmatics],
  },
  blacklistKeys: [
    "activeBlock",
    "copiedBlock",
    "activeRequirementsDocument",
    "activeAttachment",
    "activePropertyInstance",
    "inboxSettings",
  ],
});

const appStore = AppStore.create({
  env: envStore,
}) as IAppStore;

// TODO: Add middleware for logging once we understand our needs better
// addMiddleware(appStore, (call, next) => {
//   if (call.type === "action" && !call.name.startsWith("updatePresence")) {
//     console.log(`[MST] ${call.name}: ${JSON.stringify(call.args)}`);
//   }
//
//   next(call);
// });

makeInspectable(appStore);

export default appStore;
