import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Intent } from "@blueprintjs/core";
import Text, { TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IntegrationCallout } from "@components/IntegrationCallout";
import useMicrosoftTeamsIntegration from "@rollup-api/hooks/useMicrosoftTeamsIntegration";
import { MicrosoftTeam, MicrosoftTeamsLinkChannelDto } from "@rollup-api/models/integrations";
import appStore from "@store/AppStore";
import { IIntegration } from "@store/IntegrationStore";
import { rollupClient } from "src/core/api";

import ConnectIntegrationCard from "../ConnectIntegrationCard";

import MicrosoftTeamsSettingsAddNewLink from "./MicrosoftTeamsSettingsAddNewLink";
import MicrosoftTeamsSubscriptionsList from "./MicrosoftTeamsSubscriptionsList";

import styles from "./MicrosoftTeamsIntegration.module.scss";

const MicrosoftTeamsSettingsInternal = () => {
  const { integrationId } = useParams();
  const [teamsList, setTeamsList] = useState<MicrosoftTeam[]>([]);
  const [loading, setLoading] = useState(false);
  const [add, setAdd] = useState(false);
  const msTeamsIntegrationConfig: IIntegration | undefined = appStore.settingsModel?.integrationsMap.get(integrationId || "");
  const { linkChannel, subscriptions, unlinkChannel, updateChannelEvents } = useMicrosoftTeamsIntegration(msTeamsIntegrationConfig);

  useEffect(() => {
    setLoading(true);
    rollupClient.integrations
      .listMicrosoftTeams()
      .then(response => setTeamsList(response.data || []))
      .finally(() => setLoading(false));
  }, []);

  if (!msTeamsIntegrationConfig) {
    return null;
  }

  const toggleAdd = () => setAdd(!add);

  const handleCreateSubscription = async (channelLink: MicrosoftTeamsLinkChannelDto) => {
    setLoading(true);
    await linkChannel(channelLink.channelId, channelLink.teamId, channelLink.entityTypes);
    setAdd(false);
    setLoading(false);
  };

  return (
    <>
      <ConnectIntegrationCard
        skipAuth
        integration={msTeamsIntegrationConfig}
        description="Send notifications from channels to Rollup models"
      />
      {msTeamsIntegrationConfig.connection && (
        <>
          <IntegrationCallout integration={msTeamsIntegrationConfig.connection} />
          <div className={styles.microsoftTeamsIntegrationChannelSubscription}>
            <div className={styles.microsoftTeamsIntegrationTitle}>
              <Text variant={TextVariant.H2}>Channel notification subscription</Text>
              <Button intent={Intent.PRIMARY} large onClick={toggleAdd} e2eIdentifiers="add-sibscription" icon="add">
                Create subscription
              </Button>
            </div>
            {add && (
              <MicrosoftTeamsSettingsAddNewLink
                teams={teamsList}
                subscriptions={subscriptions}
                onSave={handleCreateSubscription}
                onCancel={toggleAdd}
                loading={loading}
              />
            )}
            <MicrosoftTeamsSubscriptionsList
              teams={teamsList}
              onUpdate={updateChannelEvents}
              onUnlink={unlinkChannel}
              subscriptions={subscriptions}
            />
          </div>
        </>
      )}
    </>
  );
};

export default observer(MicrosoftTeamsSettingsInternal);
