import { useCallback } from "react";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { EditorRightSideButtons } from "@components/EditorRightSideButtons";
import { PresenceList } from "@components/Header/PresenceList";
import DocumentActionsNav from "@components/Reports/ReportBlock/DocumentActionsNav";
import { DocumentEditor } from "@components/Requirements/RequirementsDocumentView/DocumentEditor";
import { useDocumentBlockHandlers } from "@components/Requirements/RequirementsDocumentView/DocumentEditor/useDocumentBlockHandlers";
import { RequirementAddButtonMenu } from "@components/Requirements/RequirementsDocumentView/RequirementAddButtonMenu";
import { RequirementDocumentBlockEditableText } from "@components/Requirements/RequirementsDocumentView/RequirementDocumentBlockEditableText";
import { RequirementDragButtonMenu } from "@components/Requirements/RequirementsDocumentView/RequirementDragButtonMenu";
import { getReqVerificationTagColor } from "@components/Requirements/RequirementsDocumentView/RequirementsDocumentBlock/RequirementsDocumentBlock.utils";
import {
  reportBlockTypeToReqBlockType,
  requirementBlockToDocumentBlock,
} from "@components/Requirements/RequirementsDocumentView/RequirementsDocumentView.utils";
import { Tag } from "@components/Tag";
import { RequirementBlockType } from "@rollup-api/models";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IRequirementBlock, VerificationStatus } from "@store/Requirements/RequirementBlockStore";
import { IRequirementsPage, isHeading } from "@store/Requirements/RequirementsPageStore";

import styles from "./RequirementDocumentBlockMainRow.module.scss";

interface IRequirementDocumentBlockMainRowProps {
  reqBlock: IRequirementBlock;
  dragListeners: SyntheticListenerMap;
  buttonsClassName?: string;
  onAddNote(): void;
  onAddImage(): void;
  onAddComment(): void;
  onFocus(): void;
}

const RequirementDocumentBlockMainRow = (props: IRequirementDocumentBlockMainRowProps) => {
  const { reqBlock, dragListeners, buttonsClassName, onAddNote, onAddImage, onAddComment, onFocus } = props;
  const documentBlock = requirementBlockToDocumentBlock(reqBlock);
  const documentBlocks = (reqBlock.parentPage as IRequirementsPage).validatedBlocks.map(requirementBlockToDocumentBlock);
  const workspace = useWorkspace();

  const handleAddBlock = (type: RequirementBlockType): string | undefined => {
    if (reqBlock.parentPage) {
      const currentBlockIndex = documentBlocks.findIndex(block => block.id === documentBlock.id);
      const orderIndex = currentBlockIndex + 1;
      const newReqBlock = workspace.addRequirementsBlock(reqBlock.parentPage, type, undefined, orderIndex);
      setTimeout(() => {
        const newBlockElement = newReqBlock && document.getElementById(newReqBlock.id);
        (newBlockElement?.firstChild as HTMLDivElement)?.focus();
      }, 50);
      return newReqBlock?.id;
    }
  };

  const handleAddBlockAtPosition = (type: RollupEditorType): string | undefined => {
    const reqType = reportBlockTypeToReqBlockType(type);
    return handleAddBlock(reqType);
  };

  const useHandlersParams = { documentBlock, documentBlocks, onAddBlock: handleAddBlockAtPosition, onFocus };
  const { handleFocus, handleBlur, handleEditorReady } = useDocumentBlockHandlers(useHandlersParams);
  const { handleEnterKeyDown, handleModEnterKeyDown, handleArrowUpKey } = useDocumentBlockHandlers(useHandlersParams);
  const { handleShiftEnterKeyDown, handleBackspaceKeyDown, handleArrowDownKey } = useDocumentBlockHandlers(useHandlersParams);

  const handleBlockUpdate = useCallback(
    (content: string) => {
      if (isAlive(reqBlock)) {
        reqBlock.setLabel(content);
      }
    },
    [reqBlock]
  );

  const handleDelete = () => {
    if (appStore.ui.selectedIds.length && appStore.ui.selectedIds.includes(reqBlock.id)) {
      workspace?.deleteRequirementBlocks(appStore.ui.selectedIds);
    } else {
      appStore.workspaceModel?.deleteRequirementBlock(reqBlock);
    }
  };

  const renderIdField = () => {
    if (isHeading(reqBlock.type)) {
      // placeholder so the grid structure is not disrupted for heading blocks
      return <div />;
    }

    return (
      <RequirementDocumentBlockEditableText
        className={styles.requirementDocumentBlockMainRowIdField}
        text={reqBlock.computedVisibleId}
        placeholder="ID"
        onConfirm={reqBlock.setVisibleId}
      />
    );
  };

  const renderVerificationTag = () => {
    if (isHeading(reqBlock.type) || reqBlock.verificationStatus === VerificationStatus.Pending) {
      // placeholder so the grid structure is not disrupted for heading blocks
      return <div />;
    }

    return (
      <div className={styles.requirementDocumentBlockMainRowTagContainer}>
        <Tag
          className={styles.requirementDocumentBlockMainRowTag}
          color={getReqVerificationTagColor(reqBlock.verificationStatus)}
          multiline
        >
          {reqBlock.verificationStatus}
        </Tag>
      </div>
    );
  };

  return (
    <>
      <DocumentActionsNav
        className={buttonsClassName}
        hasSingleBlock={documentBlocks.length === 1}
        dragListeners={dragListeners}
        addButtonMenuElement={<RequirementAddButtonMenu onAddBlock={handleAddBlock} />}
        dragButtonMenuElement={<RequirementDragButtonMenu onAddNote={onAddNote} onAddImage={onAddImage} onDelete={handleDelete} />}
      />
      <PresenceList entityId={reqBlock.id} />
      {renderIdField()}
      <DocumentEditor
        onEditorReady={handleEditorReady}
        documentBlock={documentBlock}
        onUpdate={handleBlockUpdate}
        eventHandlers={{
          onFocus: handleFocus,
          onBlur: handleBlur,
          onEnterKeyDown: handleEnterKeyDown,
          onModEnterKeyDown: handleModEnterKeyDown,
          onShiftEnterKeyDown: handleShiftEnterKeyDown,
          onBackspaceKeyDown: handleBackspaceKeyDown,
          onArrowDownKey: handleArrowDownKey,
          onArrowUpKey: handleArrowUpKey,
        }}
        disableDashNavigation
      />
      {renderVerificationTag()}
      <EditorRightSideButtons
        className={buttonsClassName}
        hasComments={reqBlock.hasComments}
        onAddComment={onAddComment}
        onOpenDetails={() => appStore.env.showReqDetailsPanel(reqBlock.id)}
      />
    </>
  );
};

export default observer(RequirementDocumentBlockMainRow);
